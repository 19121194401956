import React, { useState } from 'react';
import Barcode from "react-barcode";
import ReactToPrint from "react-to-print";
import BarcodeSticker from "../ui/BarcodeSticker";
import { Button, Input, Label } from 'reactstrap';

function PatientBarcodes(props: any) {
  const [selectedBarcodes, setSelectedBarcodes] = useState<string[]>([])
  const { patient } = props;
  const pageStyle = `
      @page {
        size: auto;
        margin: 0;
      }
      @media print {
        html, body {
             width:60mm;
          height: 60mm;
          padding-top:10px;
        }
        body {
            padding-left:10px;
        }
      }
    `;

    const getBarcodesContent = () =>{
    let barcodeContent =  document.createElement('div') 

    selectedBarcodes.forEach(type => {
    let barcode = document.getElementById(type)
      if(barcode){
        let clone = barcode.cloneNode(true)
        barcodeContent.appendChild(clone)
      }
    });
      return barcodeContent
    }

    const handleCheckPrint = (barcode:string, isSelected:boolean)=>{
      let barcodeList:any = [...selectedBarcodes]
      if(isSelected){
        barcodeList.push(barcode)
      }else {
       barcodeList =  barcodeList.filter((e: string)=> barcode!==e)
      }
      setSelectedBarcodes(barcodeList)
    }

    

    const selectAll = ()=>{
      setSelectedBarcodes(['STOOL', "URINE", "BLOOD-CBC", 'BLOOD-ELISA', 'BLOOD-BIOCHEMISTRY'])
    }

  return (
    <div className="card ">
      <div className="card-content collapse show">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
            <Button onClick={selectAll}>Select all</Button>
              <ReactToPrint
                pageStyle={pageStyle}
                removeAfterPrint = {false}
                documentTitle={patient.passportNo}
                content={getBarcodesContent}
                trigger={() => <button disabled={selectedBarcodes.length?false:true} className="btn btn-info">Print</button>} />
            </div>
          </div>
          <div className='mt-1'>
            <div id={'barcodes-content'}>
              <BarcodeSticker   patient={patient} type={'STOOL'} description={'HELM, GIAR, BILHAR'} bNumber={patient.barCode} />
              <Label checkbox style={{marginLeft:'156px', fontSize:'17px'}}><Input checked={selectedBarcodes.includes('STOOL')} onChange={(e)=>{handleCheckPrint('STOOL', e.target.checked)}} style={{ height:'15px', width:'15px'}} type="checkbox" />Print</Label>
              
              <BarcodeSticker   patient={patient} type={'URINE'} description={'SUGAR,AL,BILHA,ETC'} bNumber={patient.barCode} />
              <Label checkbox style={{marginLeft:'156px', fontSize:'17px'}}><Input checked={selectedBarcodes.includes('URINE')} onChange={(e)=>{handleCheckPrint('URINE', e.target.checked)}} style={{ height:'15px', width:'15px'}} type="checkbox" />Print</Label>
              
              <BarcodeSticker   patient={patient} type={'BLOOD-CBC'} description={'HGB,BGROUP,MP,MFP'} bNumber={patient.barCode} />
              <Label checkbox style={{marginLeft:'156px', fontSize:'17px'}}><Input checked={selectedBarcodes.includes('BLOOD-CBC')} onChange={(e)=>{handleCheckPrint('BLOOD-CBC', e.target.checked)}} style={{ height:'15px', width:'15px'}} type="checkbox" />Print</Label>
              
              <BarcodeSticker   patient={patient} type={'BLOOD-ELISA'} description={'HCV,HIV,HBsAG'} bNumber={patient.barCode} />
              <Label checkbox style={{marginLeft:'156px', fontSize:'17px'}}><Input checked={selectedBarcodes.includes('BLOOD-ELISA')} onChange={(e)=>{handleCheckPrint('BLOOD-ELISA', e.target.checked)}} style={{ height:'15px', width:'15px'}} type="checkbox" />Print</Label>
            
              <BarcodeSticker   patient={patient} type={'BLOOD-BIOCHEMISTRY'} description={'GLUC,TBIL,SGPT,SGOT,CREA'} bNumber={patient.barCode} />
              <Label checkbox style={{marginLeft:'156px', fontSize:'17px'}}><Input checked={selectedBarcodes.includes('BLOOD-BIOCHEMISTRY')} onChange={(e)=>{handleCheckPrint('BLOOD-BIOCHEMISTRY', e.target.checked)}} style={{ height:'15px', width:'15px'}} type="checkbox" />Print</Label>
            
            </div>
          </div>

        </div>
      </div>
    </div>

  );
}

export default PatientBarcodes;
