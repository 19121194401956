import {API_ROUTES} from "../utils/constants";
import http from "../config/httpConfig";


const save = async (json:{}) => {
    return http.post(API_ROUTES.COMBINED_REPORT_POST, json);
};

const findById = async (id:bigint) => {
    return http.get(API_ROUTES.COMBINED_REPORT_GET+id);
};

const getAll = async () => {
    return http.get(API_ROUTES.COMBINED_REPORT_ALL);
};

const findAll = async () =>{
    return await http.get(API_ROUTES.COMBINED_REPORT_ALL);
};

const deleteById = async (id:bigint) => {
    return http.delete(API_ROUTES.COMBINED_REPORT_GET+id);
};

const findAllByAllColumn = async (data:{pageNumber: number,pageSize:number,text:string}) => {
    return http.post(API_ROUTES.COMBINED_REPORT_ADVANCE_SEARCH,data);
};

const findAllByDate = async (data:any) => {
    return http.post(API_ROUTES.COMBINED_REPORT_FIND_BY_DATE,data);
};
const updateColumn = async (data:any) => {
    return http.post(API_ROUTES.COMBINED_REPORT_UPDATE_COLUMN,data);
};

const CombinedReportService = {
    save,
    getAll,
    findById,
    findAll,
    deleteById,
    findAllByAllColumn,
    findAllByDate,
    updateColumn,
}
export default CombinedReportService;
