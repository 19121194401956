import React, {useEffect, useState} from 'react';
import moment from "moment";
import CombinedReportService from "../../services/CombinedReportService";
import {useReactToPrint} from "react-to-print";
import PdfUtil from "../../utils/PdfUtil";
import ReportSettingsService from '../../services/ReportSettingsService';

function CombinedGCCReport(props:any) {
    const {type} = props;
    const [data, setDate] = useState<any[]>([]);
    const [value, setValues] = useState<any>({});
    let [page, setPage] = useState({
        type: 'COMBINED_REPORT_GCC',
        from: moment(new Date()).format('YYYY-MM-DD'),
        to: moment(new Date()).format('YYYY-MM-DD')
    });
    const handleCellChange = (id:number,columnName:string, newValue:string) => {
        // Update the local state with the new value
        CombinedReportService.updateColumn({id,columnName,value:newValue}).then(response => {
            console.log(response);
        }).catch(reason => {
            console.log(reason);
        });
    }
    const dateHandler = (e: any) => {
        if (e.target.name === 'from'){
            page.from = e.target.value;
        }
        if (e.target.name === 'to'){
            page.to = e.target.value;
        }
        loadData();
    }
    const loadData = () => {
        CombinedReportService.findAllByDate(page).then(response => {
            setDate(response.data);
        }).catch(reason => {
            console.log(reason)
        });
    }

    const loadCurrentValues = ()=>{
        ReportSettingsService.find().then(response => {
            console.log("response", response);
            
            setValues(response.data);
        })
    }

    useEffect(() => {
        loadData();
        loadCurrentValues();
    },[]);
    

    const onPdf = () => {
        // PdfUtil.downloadPdfP("data", "report-print");
        PdfUtil.downloadPdfP("data", "report-print");
    }

    const handlePrint = useReactToPrint({
        content: () => document.getElementById('report-print'),
        pageStyle: '@page { size: landscape !important; }',
    });

    return (
        <div className={'lab-report'}>
            <div className="input-group width-50-per">
                <input type="date" className="form-control mr-1" placeholder="Date From" name={'from'} onChange={dateHandler} value={page.from}/>
                <input type="date" className="form-control mr-1" placeholder="Date To" name={'to'} onChange={dateHandler} value={page.to}/>
                <button type="button" onClick={handlePrint}
                        className="btn btn-secondary btn-min-width mr-1 mb-1">Print
                </button>
                <button type="button" onClick={onPdf}
                        className="btn btn-primary btn-min-width mr-1 mb-1">Download
                </button>
            </div>
            {/*<h3 className={'text-center my-2'}>Combined report</h3>*/}
            <div id={'report-print'}>
                <div className="table-container lab-report" >
                    <div style={{textAlign: "center", alignContent:"center"}}>
                        <img src={value.invoiceLogo} alt="" width="80px" height="80px"/>
                        <p className="my-1"><h3>{value.businessName}</h3>{"      Address: "+ value.invoiceAddress}</p>
                        <p className="my-1"><h3>{"COMBINED REPORT"}</h3></p>
                    </div>
                    <table className={'mt-1'}>
                        <thead className="bg-primary">
                        <tr>
                            <th rowSpan={2}>Patient Id</th>
                            <th rowSpan={2}>Test Date</th>
                            <th rowSpan={2}>HBsAg</th>
                            <th rowSpan={2}>HIV (1+2)</th>
                            <th rowSpan={2}>Anti-HCV</th>
                            <th rowSpan={2}>VDRL</th>
                            <th rowSpan={2}>TPHA</th>
                            <th>Gluc</th>
                            <th>S.Bili</th>
                            <th>SGPT</th>
                            <th>SGOT</th>
                            <th>S.Creat</th>
                            <th>HGB</th>
                            <th rowSpan={2}>MP</th>
                            <th rowSpan={2}>FILARIASIS</th>
                            <th colSpan={3}>Urine</th>
                            <th colSpan={2}>Stool</th>
                            <th colSpan={2}>Blood Group</th>
                            <th rowSpan={2}>Remarks</th>
                        </tr>
                        <tr>
                            <th>m.mol/l </th>
                            <th>mg/dl</th>
                            <th>U/L</th>
                            <th>U/L</th>
                            <th>mg/dl</th>
                            <th>gm/dl</th>
                            <th>Albu</th>
                            <th>Sugar</th>
                            <th>PT</th>
                            <th>HELMINTHS</th>
                            <th>C/S</th>
                            <th>B Group</th>
                            <th>RH Factor</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            data.map((json:any, index:number) => (
                                <tr key={index}>
                                    <td>{json.patientId}</td>
                                    <td>{json.date?.substring(0,10).split('-').reverse().join('/')}</td>
                                    <td>{json.hbsag?.toUpperCase()}</td>
                                    <td>{json.hiv?.toUpperCase()}</td>
                                    <td>{json.hcv?.toUpperCase()}</td>
                                    <td contentEditable={true} onBlur={e => handleCellChange(json.id,'vdrl', e.target.innerText)}>{json.vdrl?json.vdrl:"N/R"}</td>
                                    <td contentEditable={true} onBlur={e => handleCellChange(json.id,'tpha', e.target.innerText)}>{json.tpha?.toUpperCase()}</td>
                                    <td>{json.gluc}</td>
                                    <td>{json.sbill}</td>
                                    <td>{json.sgpt}</td>
                                    <td>{json.sgot}</td>
                                    <td>{json.screat}</td>
                                    <td contentEditable={true} onBlur={e => handleCellChange(json.id,'hgb', e.target.innerText)}>{json.hgb}</td>
                                    <td contentEditable={true} onBlur={e => handleCellChange(json.id,'mp', e.target.innerText)}>{json.mp?json.mp:"NEG."}</td>
                                    <td contentEditable={true} onBlur={e => handleCellChange(json.id,'filariasis', e.target.innerText)}>{json.filariasis?json.filariasis:"NEG."}</td>
                                    <td contentEditable={true} onBlur={e => handleCellChange(json.id,'albu', e.target.innerText)}>{json.albu?json.albu:"NILL"}</td>
                                    <td contentEditable={true} onBlur={e => handleCellChange(json.id,'sugar', e.target.innerText)}>{json.sugar?json.sugar:"NILL"}</td>
                                    <td contentEditable={true} onBlur={e => handleCellChange(json.id,'pt', e.target.innerText)}>{json.pt}</td>
                                    <td contentEditable={true} onBlur={e => handleCellChange(json.id,'helminths', e.target.innerText)}>{json.helminths?json.helminths:"NAD"}</td>
                                    <td contentEditable={true} onBlur={e => handleCellChange(json.id,'cs', e.target.innerText)}>{json.cs?json.cs:"N/G"}</td>
                                    <td contentEditable={true} onBlur={e => handleCellChange(json.id,'bloodGroup', e.target.innerText)}>{json.bloodGroup}</td>
                                    <td contentEditable={true} onBlur={e => handleCellChange(json.id,'rhFactor', e.target.innerText)}>{json.rhFactor}</td>
                                    <td contentEditable={true} onBlur={e => handleCellChange(json.id,'remark', e.target.innerText)}>{json.remark}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default CombinedGCCReport;