import React from 'react';
import FormInput from "./FormInput";
import FormSelect from "./FormSelect";
import FormTextArea from "./FormTextArea";
import FormCheckbox from "./FormCheckbox";
import FormSelectFilter from "./FormSelectFilter";


function FormField(props: any) {
    if (props.type.toLowerCase() == 'select') {
        return (<FormSelect {...props} />);
    } else if (props.type.toLowerCase() == 'textarea') {
        return (<FormTextArea {...props} />);
    } else if (props.type.toLowerCase() == 'checkbox') {
        return (<FormCheckbox {...props} />);
    } else if (props.type.toLowerCase() == 'selectfilter') {
        return (<FormSelectFilter {...props} />);
    } else {
        return (<FormInput {...props} />);
    }
}
export default FormField;
