import React, {useEffect, useState} from 'react';
import moment from "moment";
import {useReactToPrint} from "react-to-print";
import PdfUtil from "../../utils/PdfUtil";
import CombinedReportService from "../../services/CombinedReportService";
import ReportSettingsService from '../../services/ReportSettingsService';

function BioChemistryReport(props:any) {
    const {type} = props;
    const [data, setDate] = useState<any[]>([]);
    const [value, setValues] = useState<any>({});
    let [page, setPage] = useState({
        type: type,
        from: moment(new Date()).format('YYYY-MM-DD'),
        to: moment(new Date()).format('YYYY-MM-DD')
    });
    const dateHandler = (e: any) => {
        if (e.target.name === 'from'){
            page.from = e.target.value;
        }
        if (e.target.name === 'to'){
            page.to = e.target.value;
        }
        loadData();
    }
    const loadData = () => {
        CombinedReportService.findAllByDate(page).then(response => {
            setDate(response.data);
        }).catch(reason => {
            console.log(reason)
        });
    }

    const loadCurrentValues = ()=>{
        ReportSettingsService.find().then(response => {
            console.log("response", response);
            
            setValues(response.data);
        })
    }

    useEffect(() => {
        loadData();
        loadCurrentValues();
    },[]);
    

    const onPdf = () => {
        // PdfUtil.downloadPdfP("data", "report-print");
        PdfUtil.downloadPdf("data", "report-print", "bht", "9px");
    }

    const handlePrint = useReactToPrint({
        content: () => document.getElementById('report-print'),
    });

    return (
        <div className={'lab-report'}>
            <div className="input-group width-50-per">
                <input type="date" className="form-control mr-1" placeholder="Date From" name={'from'} onChange={dateHandler} value={page.from}/>
                <input type="date" className="form-control mr-1" placeholder="Date To" name={'to'} onChange={dateHandler} value={page.to}/>
                <button type="button" onClick={handlePrint}
                        className="btn btn-secondary btn-min-width mr-1 mb-1">Print
                </button>
                <button type="button" onClick={onPdf}
                        className="btn btn-primary btn-min-width mr-1 mb-1">Download
                </button>
            </div>
            <div id={'report-print'}>
                <div className="table-container lab-report" >
                    <div style={{textAlign: "center"}}>
                        <img src={value.invoiceLogo} alt="" width="80px" height="80px"/>
                        <p className="my-1"><h3>{value.businessName}</h3>{"      Address: "+ value.invoiceAddress}</p>
                    </div>
                    <table className={'mt-1'}>
                        <thead className="bg-primary">
                        <tr>
                            <th rowSpan={2}>DATE</th>
                            <th rowSpan={2}>Patient Id</th>
                            <th>Gluc</th>
                            <th>S.Bili</th>
                            <th>SGPT</th>
                            <th>SGOT</th>
                            <th>S.Creat</th>
                        </tr>
                        <tr>
                            <th>m.mol/l </th>
                            <th>mg/dl</th>
                            <th>U/L</th>
                            <th>U/L</th>
                            <th>mg/dl</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            data.map((json:any, index:number) => (
                                <tr key={index}>
                                    <td>{moment(json.createdDate).format('YYYY-MM-DD')}</td>
                                    <td>{json.patientId}</td>
                                    <td>{json.gluc}</td>
                                    <td>{json.sbill}</td>
                                    <td>{json.sgpt}</td>
                                    <td>{json.sgot}</td>
                                    <td>{json.screat}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default BioChemistryReport;