import React, { useEffect, useState } from 'react';
import http from "../../config/httpConfig";
import Select from "react-select";

function FormSelectFilter(props: any) {
    let { label, errorMessage, onChange, column, id, ajax, mapping, values, disableFirstOption,name, ...inputProps } = props;
    let [options, setOptions] = useState([]);

    const initOptions = () => {
            http.get(mapping?.path).then(response => {
                let data: any = [];
                response.data.forEach((record: any) => {
                    data.push({ value: record[mapping.value], label: record[mapping.text] })
                });
                setOptions(data);
            }).catch(reason => {
                console.log(reason);
            })
        
    }

    useEffect(() => {
            initOptions();
    }, [])

    return (
        <div className={"mb-1 col-md-" + column}>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <label htmlFor={id}>{label}{inputProps.required ? <span style={{ color: "red", marginLeft: "5px" }}>*</span> : ''} </label>
            </div>
           <Select options={options}  onChange={onChange}  isClearable={true}
        isSearchable={true}/>
           <div className="invalid-feedback">{inputProps.errorMessage}</div>
        </div>
    );
}

export default FormSelectFilter;
