import React, { useRef, useState, useEffect } from "react";
import { MODEL } from "../../utils/FormFields";
import PatientService from "../../services/PatientService";
import http from "../../config/httpConfig";
import { API_ROUTES } from "../../utils/constants";

const XrayViewer = (props: any) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [scale, setScale] = useState<number>(1);
  const [isZoomed, setIsZoomed] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [dragStartPos, setDragStartPos] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [imagePos, setImagePos] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

  const [report, setReport] = useState(MODEL.REPORT);
  const [patient, setPatient] = useState(props?.patients);

  useEffect(() => {
    PatientService.findById(patient?.id).then((response) => {
      setReport(response.data.report);
    });
  }, [patient?.id]);

  useEffect(() => {
    const container = containerRef.current;

    const handleDoubleClick = () => {
      if (isZoomed) {
        setScale(1);
        setIsZoomed(false);
      } else {
        setScale(2);
        setIsZoomed(true);
      }
    };

    const handleScroll = (event: WheelEvent) => {
      if (!isZoomed) {
        return;
      }

      event.preventDefault();

      const container = containerRef.current;
      if (!container) return;

      const containerWidth = container.offsetWidth;
      const containerHeight = container.offsetHeight;

      const mouseX = event.clientX - container.offsetLeft;
      const mouseY = event.clientY - container.offsetTop;

      const image = imageRef.current;
      if (!image) return;

      const imageWidth = image.width * scale;
      const imageHeight = image.height * scale;

      const scrollDelta = Math.max(-1, Math.min(1, event.deltaY));
      const zoomIntensity = 0.1;

      let newScale = scale + scrollDelta * zoomIntensity;
      newScale = Math.max(0.5, Math.min(3, newScale));

      const deltaScale = newScale - scale;
      const adjustedImageWidth = imageWidth * newScale;
      const adjustedImageHeight = imageHeight * newScale;

      const containerRect = container.getBoundingClientRect();
      const mouseXPercentage = (mouseX - containerRect.left) / containerWidth;
      const mouseYPercentage = (mouseY - containerRect.top) / containerHeight;

      const newImagePosX = imagePos.x - deltaScale * mouseXPercentage * adjustedImageWidth;
      const newImagePosY = imagePos.y - deltaScale * mouseYPercentage * adjustedImageHeight;

      setScale(newScale);
      setImagePos({ x: newImagePosX, y: newImagePosY });
    };

    if (container) {
      container.addEventListener("dblclick", handleDoubleClick);
      container.addEventListener("wheel", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("dblclick", handleDoubleClick);
        container.removeEventListener("wheel", handleScroll);
      }
    };
  }, [isZoomed, scale, imagePos]);

  const getRemarks = (e:any)=>
  {
    http.put(API_ROUTES.PATIENT_REPORT_VALUES_PUT, {
      id: patient.id,
      columnName: e.target.name,
      value: e.target.value,
    }).then(response => {
      console.log(response);
    })

  }

  useEffect(() => {
    const image = imageRef.current;

    if (image) {
      const container = containerRef.current;
      const containerWidth = container?.offsetWidth || 0;
      const containerHeight = container?.offsetHeight || 0;
      const imageWidth = image.width * scale;
      const imageHeight = image.height * scale;

      const maxX = Math.max(containerWidth - imageWidth, 0);
      const maxY = Math.max(containerHeight - imageHeight, 0);

      let posX = imagePos.x;
      let posY = imagePos.y;

      const handleMouseDown = (event: MouseEvent) => {
        if (event.button !== 0 || !isZoomed) {
          return;
        }

        event.preventDefault();
        setIsDragging(true);
        setDragStartPos({ x: event.clientX, y: event.clientY });
      };

      const handleMouseMove = (event: MouseEvent) => {
        if (!isDragging) {
          return;
        }

        const diffX = event.clientX - dragStartPos.x;
        const diffY = event.clientY - dragStartPos.y;

        posX = Math.min(Math.max(imagePos.x + diffX, maxX), 0);
        posY = Math.min(Math.max(imagePos.y + diffY, maxY), 0);

        setImagePos({ x: posX, y: posY });
      };

      const handleMouseUp = () => {
        setIsDragging(false);
      };

      image.style.transform = `scale(${scale})`;
      image.style.left = `${posX}px`;
      image.style.top = `${posY}px`;
      image.style.cursor = isZoomed ? (isDragging ? "grabbing" : "grab") : "default";

      if (isZoomed) {
        image.addEventListener("mousedown", handleMouseDown);
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
      } else {
        image.removeEventListener("mousedown", handleMouseDown);
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      }
    }
  }, [scale, isZoomed, isDragging, dragStartPos, imagePos]);

  return (<>
    <div
      ref={containerRef}
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "scroll",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        ref={imageRef}
        src={report.xrayImage}
        alt="X-ray"
        style={{
          transformOrigin: "top left",
          userSelect: "none",
          cursor: isZoomed ? (isDragging ? "grabbing" : "grab") : "default",
          left: `${imagePos.x}px`,
          top: `${imagePos.y}px`,
        }}
      />

    </div>
    <div  style={{
      width:"100%"
    }}>
    <div className={"col-md mb-1"}>
            <div style={{display: "flex",justifyContent: "flex-start"}}>
                <label >Remarks<span style={{color: "red",marginLeft: "5px"}}></span> </label>
            </div>
            <textarea style={{
      width:"100%",
      borderRadius:"10px"
    }} 
    onChange={getRemarks}
    name="xrayImageRemarks"
    
  />
           
        </div>
    </div>
  </>
  );
};

export default XrayViewer;
