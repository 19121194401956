import React, {useEffect, useState} from 'react';
import PdfUtil from "../../utils/PdfUtil";
import {useReactToPrint} from "react-to-print";
import LabReportService from "../../services/LabReportService";
import AuthService from "../../services/AuthService";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import ReportSettingsService from '../../services/ReportSettingsService';

function LabReportDetails(props:any) {
    const {type} = props;
    const navigate = useNavigate();
    const [data, setDate] = useState<any[]>([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [value, setValues] = useState<any>({});
    let [page, setPage] = useState({
        type: type,
        from: moment(new Date()).format('YYYY-MM-DD'),
        to: moment(new Date()).format('YYYY-MM-DD')
    });

    const dateHandler = (e: any) => {
        if (e.target.name === 'from'){
            page.from = e.target.value;
        }
        if (e.target.name === 'to'){
            page.to = e.target.value;
        }
        loadData();
    }
    const onPdf = () => {
        PdfUtil.downloadPdfP("data", "report-print");
    }

    const handlePrint = useReactToPrint({
        content: () => document.getElementById('report-print'),
    });

    const loadData = () => {
        LabReportService.findAllByTypeAndDate(page).then(response => {
            setDate(response.data);
            setIsLoaded(true);
        }).catch(reason => {
            console.log(reason)
        });
    }

    const loadCurrentValues = ()=>{
        ReportSettingsService.find().then(response => {
            console.log("response", response);
            
            setValues(response.data);
        })
    }

    useEffect(() => {
        loadData();
        loadCurrentValues();
    },[])

    return (
        <div>
            <div className="input-group width-50-per">
                <input type="date" className="form-control mr-1" placeholder="Date From" name={'from'} onChange={dateHandler}/>
                <input type="date" className="form-control mr-1" placeholder="Date To" name={'to'} onChange={dateHandler}/>
                <button type="button" onClick={handlePrint}
                        className="btn btn-secondary btn-min-width mr-1 mb-1">Print
                </button>
                <button type="button" onClick={onPdf}
                        className="btn btn-primary btn-min-width mr-1 mb-1">Download
                </button>
            </div>
            <div id={'report-print'}>
                <div className="table-container lab-report" >
                    <div style={{textAlign: "center"}}>
                        <img src={value.invoiceLogo} alt="" width="80px" height="80px"/>
                        <p className="my-1"><h3>{value.businessName}</h3>{"      Address: "+ value.invoiceAddress }</p>
                        
                        
                    </div>
                    <table className="">
                        <thead className="bg-primary">
                        <tr>
                            <th>SL</th>
                            <th>Test Date</th>
                            <th>Serial No</th>
                            <th>Test Name</th>
                            <th>Well</th>
                            <th>Test Value</th>
                            <th>Test Result</th>
                            <th>Final Result</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            data.map((json:any, index:number) => (
                                <tr key={index}>
                                    <td scope="row">{index+1}</td>
                                    <td>{json.date?.substring(0,19).replace('T',' ')}</td>
                                    <td>{json.patientId}</td>
                                    <td>{json.type}</td>
                                    <td>{json.wellLocation}</td>
                                    <td>{json.readerValue}</td>
                                    <td className={json.qualValue.includes('Pos')?'red':'black'}>{json.qualValue.toUpperCase()}</td>
                                    <td></td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default LabReportDetails;
