import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import PatientReportContent from "../../../components/detail/PatientReportContent";
import PatientReportContent1 from '../../../components/detail/PatientReportContent1';
import { MODEL } from '../../../utils/FormFields';
import XrayViewerPatientReport from '../../../components/detail/XrayDetailPatientReport';

function PatientReport(props: any) {
  
  const [patient, setPatient] = useState(MODEL.PATIENT);
  const [action, setAction] = useState('none');
  const { patients, formtype } = props

  let { id } = useParams();

  const XrayImage = (e: any) => {
    setPatient(patients);
    setAction('xray-image');
  }

  return (
    <>
    <div className="app-content content">
      <div className="content-wrapper">
        <div className="content-wrapper-before"></div>
        <div className="content-header row">
          <div className="content-header-left col-md-4 col-12 mb-2">
            <h3 className="content-header-title">Patient Medical Report</h3>
          </div>
        </div>
        <div className="content-body">
          <div className="row">
            <div className="col-12">
              <div className="card">
                {formtype === 1
                  ?
                  <> <PatientReportContent1 patient={patients} /></> :
                   <><PatientReportContent patient={patients} /></>
                }
                

              </div>
              
            </div>
            <div  style={{alignContent: 'center' }}  className="col-6">
            </div>
            <div   className="col-6">
            
              <button  className="btn btn-bg-gradient-x-blue-cyan round border-3 border-white btn-min-width box-shadow-2 my-1" onClick={XrayImage}> X-Ray View </button>
            </div>
              
          </div>
        </div>
      </div>
    </div>

    

{
  action === 'xray-image' ?
    <div className={`modal fade fadeIn show`} role="dialog"
      style={{ display: 'block' }} data-backdrop="false" tabIndex={-1}>
      <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body p-0 scroll-85">
            <XrayViewerPatientReport patients={patients} />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-sm btn-secondary"
              onClick={() => setAction('none')}>Close
            </button>
          </div>
        </div>
      </div>
    </div> : ""
}
</>
  );
}

export default PatientReport;
