import React, {useEffect, useState} from 'react';
import AuthService from "../../../services/AuthService";
import {useNavigate} from "react-router-dom";
import LabReportService from "../../../services/LabReportService";
import LabReportForm from "../../../components/forms/LabReportForm";
import FuncUtil from "../../../utils/FuncUtil";
import CombinedReport from "../../../components/detail/CombinedReport";
import BioChemistryReport from "../../../components/detail/BǐoChemistry";
import HematologyReport from "../../../components/detail/HematologyReport";
import LabReportDetails from "../../../components/detail/LabReportDetails";
import CombinedMLReport from "../../../components/detail/CombinedMLReport";
import CombinedGCCReport from "../../../components/detail/CombinedGCCReport";

function UploadReports(props: any) {
    const navigate = useNavigate();
    const permission = AuthService.getPermission('ACCOUNT');
    const [data, setDate] = useState<any[]>([]);
    const [action, setAction] = useState('none');
    const [reportType, setReportType] = useState('none');
    const [isLoaded, setIsLoaded] = useState(false);
    const [report, setReport] = useState({});
    const [refresh, doRefresh] = useState(0);

    const rowActions = (row: any) => {
        return (
            <>
                {permission.edit?<button type="button" className="btn btn-xss btn-info box-shadow-1 my-0 mx-1"
                        data-record={JSON.stringify(row)} onClick={onView}>
                    <i className="ft-eye"></i> View
                </button>:''}
            </>);
    }

    const uploadedTime = (row: any) => {
        return row.createdDate?FuncUtil.toDateTime(row.createdDate):'';
    }

    const columns: {}[] = [
        {data: "index", name: "ID", sortable: true, class: "text-center"},
        {data: "name", name: "File Name", class: ''},
        {render: uploadedTime, name: "Uploaded Time"},
        {name: "Action", render: rowActions, class: "text-center p-0"}
    ];
    const onView = (e: any) => {
        setReport(JSON.parse(e.target.dataset.record));
        setAction('view');
    }
    const onAdd = (e: any) => {
        setAction('add');
    }




    const loadData = () => {
        LabReportService.findAll().then(response => {
            setDate(response.data);
            setIsLoaded(true);
        }).catch(reason => {
            if (reason.code === "ERR_NETWORK") {
                navigate("/maintenance");
            }
            if (reason.response.status === 401) {
                AuthService.logout();
                navigate("/login");
            }
        });
    }

    const handleSearch = (params: any) => {
        LabReportService.findAllByAllColumn(params).then(response => {
            setDate(response.data);
        }).catch(reason => {
            if (reason.code === "ERR_NETWORK") {
                navigate("/maintenance");
            }
            if (reason.response.status === 401) {
                AuthService.logout();
                navigate("/login");
            }
        });
    }

    const onUpdate = (e: any) => {
        setAction('none');
        doRefresh(refresh == 0 ? 1 : 0);
    }
    const onCancel = (e: any) => {
        setAction('none');
    }

    useEffect(() => {
        if (!isLoaded) {
            loadData();
        }
    })

    return (
        <>
            <div className="app-content content">
                <div className="content-wrapper">
                    <div className="content-wrapper-before"></div>
                    <div className="content-header row">
                        <div className="content-header-left col-md-4 col-12 mb-2">
                            <h3 className="content-header-title">Upload Lab Reports</h3>
                        </div>
                        <div className="content-header-right col-md-8 col-12">
                            <div className="breadcrumbs-top float-md-right">
                                <div className="breadcrumb-wrapper mr-1">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/">Home</a>
                                        </li>
                                        <li className="breadcrumb-item"><a href="/lab">Upload</a>
                                        </li>
                                        <li className="breadcrumb-item active">Reports
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-body">
                        <div className="card">
                            <div className="card-content collapse show">
                                <div className="card-header">
                                    {permission.create?
                                        <button type={'button'} className="btn btn-sm btn-info box-shadow-1 pull-right" onClick={onAdd}>
                                            <i className="ft-plus"></i> Upload Reports
                                        </button>
                                        :''
                                    }
                                </div>
                                <div className="card-body">
                                    <div className="form-group text-center">
                                        <button type="button" onClick={event => setReportType('HBsAG')}
                                                className={`btn btn-sm btn-outline-secondary btn-min-width mr-1 mb-1 ${reportType === 'HBsAG'?'active':''}`}>HBsAG
                                        </button>
                                        <button type="button" onClick={event => setReportType('HIV')}
                                                className={`btn btn-sm btn-outline-primary btn-min-width mr-1 mb-1 ${reportType === 'HIV'?'active':''}`}>HIV
                                        </button>
                                        <button type="button" onClick={event => setReportType('HCV')}
                                                className={`btn btn-sm btn-outline-success btn-min-width mr-1 mb-1 ${reportType === 'HCV'?'active':''}`}>HCV
                                        </button>
                                        <button type="button" onClick={event => setReportType('TPHA')}
                                                className={`btn btn-sm btn-outline-info btn-min-width mr-1 mb-1 ${reportType === 'TPHA'?'active':''}`}>TPHA
                                        </button>
                                        {/* <button type="button" onClick={event => setReportType('ALL_ALSA')}
                                                className={`btn btn-sm btn-outline-warning btn-min-width mr-1 mb-1 ${reportType === 'ALL_ALSA'?'active':''}`}>ALL ALSA
                                        </button> */}
                                        <button type="button" onClick={event => setReportType('BIO_CHEMISTRY')}
                                                className={`btn btn-sm btn-outline-danger btn-min-width mr-1 mb-1 ${reportType === 'BIO_CHEMISTRY'?'active':''}`}>BIO CHEMISTRY
                                        </button>
                                        <button type="button" onClick={event => setReportType('COMBINED_REPORT')}
                                                className={`btn btn-sm btn-outline-light btn-min-width mr-1 mb-1 ${reportType === 'COMBINED_REPORT'?'active':''}`}>COMBINED REPORT
                                        </button>
                                        <button type="button" onClick={event => setReportType('COMBINED_REPORT_ML')}
                                                className={`btn btn-sm btn-outline-light btn-min-width mr-1 mb-1 ${reportType === 'COMBINED_REPORT_ML'?'active':''}`}>MALASIYA
                                        </button>
                                        <button type="button" onClick={event => setReportType('COMBINED_REPORT_GCC')}
                                                className={`btn btn-sm btn-outline-light btn-min-width mr-1 mb-1 ${reportType === 'COMBINED_REPORT_GCC'?'active':''}`}>GCC
                                        </button>
                                        <button type="button" onClick={event => setReportType('HEMATOLOGY_REPORT')}
                                                className={`btn btn-sm btn-outline-dark btn-min-width mr-1 mb-1 ${reportType === 'HEMATOLOGY_REPORT'?'active':''}`}>HEMATOLOGY REPORT
                                        </button>
                                    </div>
                                    {reportType === 'HBsAG' ? <LabReportDetails type={reportType}  />:""}
                                    {reportType === 'HIV' ? <LabReportDetails type={reportType} />:""}
                                    {reportType === 'HCV' ? <LabReportDetails type={reportType}  />:""}
                                    {reportType === 'TPHA' ? <LabReportDetails type={reportType}  />:""}
                                    {reportType === 'COMBINED_REPORT' ? <CombinedReport type={reportType}  />:""}
                                    {reportType === 'COMBINED_REPORT_ML' ? <CombinedMLReport type={reportType}  />:""}
                                    {reportType === 'COMBINED_REPORT_GCC' ? <CombinedGCCReport type={reportType}  />:""}
                                    {reportType === 'BIO_CHEMISTRY' ? <BioChemistryReport type={reportType}  />:""}
                                    {reportType === 'HEMATOLOGY_REPORT' ? <HematologyReport type={reportType}  />:""}
                                    {/*<div className="table-responsive">
                                        <table className="table">
                                            <thead className="bg-primary white">
                                            <tr>
                                                <th>#</th>
                                                <th>Type</th>
                                                <th>Report Date</th>
                                                <th>Operator</th>
                                                <th>Wave Length</th>
                                                <th>Result</th>
                                                <th className={'text-center'}>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                data.map((json, index) => (
                                                    <tr key={index}>
                                                        <th scope="row">{index +1 }</th>
                                                        <td>{json.type}</td>
                                                        <td>{json.date}</td>
                                                        <td>{json.operator}</td>
                                                        <td>{json.wavelengths}</td>
                                                        <td>{json.result}</td>
                                                        <td className={'text-right'}>
                                                            <button type="button" onClick={onView} data-record={JSON.stringify(json)} className="btn mr-1 mb-1 btn-info btn-sm">View</button>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                action == 'view' ?
                    <div className={`modal fade fadeIn show`} role="dialog"
                         style={{display: 'block'}} data-backdrop="false" tabIndex={-1}>
                        <div className="modal-dialog modal-dialog-centered modal-xl" role="document" >
                            <div className="modal-content" style={{maxHeight:700}}>
                                <div className="modal-body scroll-95">
                                    {(reportType === 'HBsAG' || reportType === 'HIV'|| reportType === 'HCV'|| reportType === 'TPHA') ? <LabReportDetails report={report} />:""}
                                    {reportType === 'COMBINED_REPORT' ? <CombinedReport report={report} />:""}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-sm btn-secondary"
                                            onClick={onCancel}>Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div> : ""
            }
            {
                action == 'add' ?
                    <div className={`modal fade fadeIn show`} role="dialog"
                         style={{display: 'block'}} data-backdrop="false" tabIndex={-1}>
                        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                            <div className="modal-content scroll-95">
                                <div className="modal-body">
                                    <LabReportForm onSuccess={onUpdate} onCancel={onCancel}/>
                                </div>
                            </div>
                        </div>
                    </div> : ""
            }
        </>
    );
}

export default UploadReports;
